import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import classes from "../../../Static/styles.module.css";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import Stack from "react-bootstrap/esm/Stack";
import axios from "axios";
import { Fragment } from "react";

const Filter = (props) => {
  const endpoint = process.env.REACT_APP_API_URL;

  const [levels, setLevels] = useState([]);
  const [standards, setStandards] = useState([]);

  // get the filters values to this component
  const handelOnFilterChange = (val) => {
    props.setStandardsFilter(val);
    props.onFilterChange(val);
  };

  useEffect(() => {
    axios
      .get(endpoint + "data_api/levels/")
      .then((response) => {
        setLevels(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(endpoint + "data_api/standards/")
      .then((response) => setStandards(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <Fragment>
      <h5 className="mt-5">Filter by Standards:</h5>

      <Stack direction="horizontal" gap={1}>
        {levels.filter((level) => level.active == true).map((level) => (
          <Dropdown key={level.id}>
            <Dropdown.Toggle
              id={level.id}
              className={classes["level-" + level.levelNumber]}
            >
              {level.levelTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              <ToggleButtonGroup
                type="checkbox"
                value={props.standardsFilter}
                onChange={handelOnFilterChange}
                vertical
                name={level.levelNumber}
              >
                {standards
                  .filter(
                    (standard) => standard.levelNumber === level.levelNumber
                  )
                  .map((standard, index) => (
                    <ToggleButton
                      name="std"
                      type="checkbox"
                      variant="outline-secondary"
                      key={standard.id}
                      id={`std-btn-${standard.id}`}
                      className={classes[`list-items-${standard.levelNumber}`]}
                      value={standard.standardTitle}
                    >
                      {standard.standardTitle}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </Dropdown.Menu>
          </Dropdown>
        ))}
      </Stack>
    </Fragment>
  );
};

export default Filter;
