import React from "react";
import Classes from "./Footer.module.css";
import Image from "react-bootstrap/esm/Image";
import BICC from "../../../Imgs/BICC.png";
import Fenix from "../../../Imgs/Fenix.png";
import Stack from "react-bootstrap/esm/Stack";

const Footer = () => {
  return (
    <footer className={Classes.footer}>
        <hr />
      <p>
        Developed for the UN by{" "}
        <a href="https://bicc.de" target="_blank" rel="noreferrer">
          BICC
        </a>{" "}
        in collaboration with{" "}
        <a href="https://www.fenix-insight.online/" target="_blank" rel="noreferrer">
          Fenix Insight
        </a>
        . &copy; 2023 
      </p>
      <Stack direction="horizontal" gap={5} className="d-flex justify-content-center">
        <a href="https://bicc.de" target="_blank" rel="noreferrer">
        <Image
          src={BICC}
          alt="Bonn International Centre for Conflict Studies"
          style={{ width: 150 }}
          srcset=""
          id="image-section"
          className="img-fluid"
        /></a>
        <a href="https://www.fenix-insight.online/" target="_blank" rel="noreferrer">
        <Image
          src={Fenix}
          alt="Fenix Insight"
          srcset=""
          id="image-section"
          style={{ width: 150 }}
          className="img-fluid"
        /></a>
      </Stack>
    </footer>
  );
};

export default Footer;
