import React from "react";
import Card from "react-bootstrap/Card";
import classes from "../../../Static/styles.module.css";
import Stack from "react-bootstrap/esm/Stack";
import Badge from "react-bootstrap/Badge";

const ResultsItem = (props) => {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  return (
    <Stack direction="horizontal">
      <Card onClick={props.onClick}>
        <Card.Header className={classes[`level-text-${props.level}`]}>
          Level {props.level}, {props.title} {props.pageNumber && <span className="float-end">Page: {props.pageNumber}</span>}
        </Card.Header>
        <Card.Body>
          <Card.Title>{props.heading1}</Card.Title>
          <Card.Text>{truncateText(props.paragraph, 300)} </Card.Text>
        </Card.Body>
      </Card>
      <Stack gap={2}>
        {props.complainceFilter.map((comp) => (
          <Badge bg="secondary">{comp}</Badge>
        ))}
      </Stack>
    </Stack>
  );
};

export default ResultsItem;
