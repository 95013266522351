import "./App.css";
import { useState, useEffect } from "react";
import Search from "./Components/Layout/Search/Search";
import Filter from "./Components/Layout/Filter/Filter";
import ComplainceFilter from "./Components/Layout/Filter/ComplainceFilter";
import Results from "./Components/Layout/Results/Results";
import AppliedFilters from "./Components/Layout/AppliedFilters/AppliedFilters";
import IddrsImg from "./Imgs/IDDRS.png";
import axios from "axios";
import WordCloud_ from "./Components/Layout/WordCloud_/WordCloud_";
import Stack from "react-bootstrap/esm/Stack";
import Container from "react-bootstrap/esm/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/esm/Button";
import { saveAs } from 'file-saver';
import ClipLoader from "react-spinners/ClipLoader";
import Classes from "./App.module.css";
import Footer from "./Components/Layout/Footer/Footer";

function App() {
  const endpoint = process.env.REACT_APP_API_URL

  const [data, setData] = useState("");
  const [phrase, setPhrase] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filtersComplaince, setFiltersComplaince] = useState([]);
  const [filtersStandards, setFiltersStandards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [standardsFilter, setStandardsFilter] = useState([]);
  const [score, setScore] = useState(8);
  


  // the useEffect hook is used to apply filters whenever the results,
  // filtersStandards, or filtersComplaince values change
  useEffect(() => {
    setFilteredResults(
      applyFilters(results, filtersStandards, filtersComplaince)
    );
  }, [results, filtersStandards, filtersComplaince]);

  //################################################################################
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setFiltersStandards([]);
    setFiltersComplaince([]);
    setCurrentPage(1) //test

    // Send the input value to Django
    axios
      .post(endpoint+"client_api/get_input/", { data })
      .then((response) => {
        setPhrase(data["phrase"]);
        setResults(response.data.results[0]); // The recived data from this api is a tuple of (results, min_score), thats why we used [0]
        setShowResults(true);
        setFilteredResults(response.data.results[0]);// The recived data from this api is a tuple of (results, min_score), thats why we used [0]
        setScore(response.data.results[1]) // The recived data from this api is a tuple of (results, min_score), thats why we used [1]
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSubmit_ = (value) => {
    setLoading(true);
    setCurrentPage(1) //test
    // Send the input value to Django
    axios
      .post(endpoint+"client_api/get_input/", {
        data: { phrase: value },
      })
      .then((response) => {
        setPhrase(value);
        setShowResults(true);
        setResults(response.data.results[0]);// The recived data from this api is a tuple of (results, min_score), thats why we used [0]
        setFilteredResults(response.data.results[0]);// The recived data from this api is a tuple of (results, min_score), thats why we used [0]
        setScore(response.data.results[1])// The recived data from this api is a tuple of (results, min_score), thats why we used [1]
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //################################################################################
  // function to apply multiple filters to the results in any order and ensure
  // that each filter is applied to the recent filtered results
  function applyFilters(results, filter1, filter2) {
    let filteredResults__ = results;

    // Apply the Standards filter if it is provided,  Array#some method checks if at least one
    // element in filter1 matches the result.Title value.
    if (filter1.length > 0) {

      filteredResults__ = filteredResults__.filter((result) => {
        return filter1.some((filter) => filter == result.Title);
      });
    }

    // Apply the second filter if it is provided
    if (filter2.length > 0) {

      filteredResults__ = filteredResults__.filter((result) => {
        return filter2.every((filter) => result[filter]);
      });
    }

    return filteredResults__;
  }
  //################################################################################

  // apply the complaince check on the results and then send the new results to the
  // results component
  function handleComplainceFilterChange(ComplainceFilterValue) {
    setFiltersComplaince(ComplainceFilterValue);
  }
  //################################################################################

  // apply the standards filter on the results and then send the new results to the
  // results component
  function handleStandardsFilterChange(StandardsFilterValue) {
    setCurrentPage(1) //test
    setFiltersStandards(StandardsFilterValue);
  }
  //################################################################################

  // append the input to the data. The data will be sent later to the
  // server side
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  //#################################################################################
  // function to export the results as a pdf file
  const handleExportPDF = async () => {
    setLoading(true);
    try {
      const response = await axios.post(endpoint+"client_api/exportPDF/", {
        params: {
          filteredResults: filteredResults, // Replace with the actual filtered results data
          phrase: phrase, // Replace with the actual phrase data
        },
      });

      // The response will contain the PDF file data, and you can use it as needed (e.g., to display or download the PDF)
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'output.pdf');
      setLoading(false);
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setLoading(false);
    }
  };

  //###########################################################################################
  const handleClearFilter = () =>{
    setFiltersStandards([]);
    setStandardsFilter([]);
  }

  return (
    <main>
      <Container>
        <Image className="mt-5" src={IddrsImg} alt="IDDRS" fluid />
        <Search onSubmit={handleSubmit} onChange={handleChange} />
        {showResults && <Filter onFilterChange={handleStandardsFilterChange} standardsFilter={standardsFilter} setStandardsFilter={setStandardsFilter}/>}
        {showResults && (
          <ComplainceFilter onFilterChange={handleComplainceFilterChange} />
        )}

        {filtersStandards.length > 0 && (
          <AppliedFilters standards={filtersStandards} clearFilter={handleClearFilter}/>
        )}
        {showResults && (
          <Button onClick={handleExportPDF}>Export | PDF</Button>
        )}
        {showResults && (
          <Results
            results={filteredResults}
            complainceFilter={filtersComplaince}
            searchedPhrase={phrase}
            currentPage = {currentPage} //test
            setCurrentPage = {setCurrentPage} //test
            score = {score}
          />
        )}

        <Stack className="mt-5" direction="horizontal" gap={5}>
          {/* <FeaturedQuestions handleButtonClick={handleSubmit_} /> */}
          <WordCloud_ handleButtonClick={handleSubmit_} />
        </Stack>

        {loading && (
        <div
          className={`d-flex justify-content-center align-items-center ${Classes.overlay}`}
        >
          <ClipLoader
            size={150}
            color="#ffffff"
            aria-label="Loading Spinner"
          ></ClipLoader>
        </div>
      )}
      </Container>
      <Footer />
    </main>
  );
}

export default App;
